import React from "react"
import SEO from "../components/seo"
import FeaturedCaseStudy from "../components/featuredCaseStudy/featuredCaseStudy"
import FormAudit from "../components/formAudit/formAudit"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ReviewsExtended from "../components/reviews/reviewsExtended"
import Logo from "../components/logo/logo"
import Triangle from "../components/triangle/triangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const slugify = require("slugify")

const Sector = ({ data: { page }, location }) => {
  return (
    <>
      <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      />

      <header className="absolute top-0 left-0 right-0 z-20 w-full pt-8 pb-8 sm:pt-10 lg:pt-16 md:pb-16 xl:pb-8 bg-secondary">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <Link to="/" aria-label="Return to the homepage">
              <Logo color={`white`} />
            </Link>
            <div className="ml-auto">
              <nav aria-labelledby="mainmenulabel" className="m-nav">
                <span id="mainmenulabel" className="sr-only">Main Menu</span>
                <ul>
                  <li className="hidden lg:inline-block">
                    <a href="#what-we-do" className="relative px-3 text-white link xl:text-lg hover:text-primary focus:text-primary xl:px-5 2xl:px-8">
                      What We Do
                    </a>
                  </li>
                  <li className="hidden lg:inline-block">
                    <a href="#lead-generation" className="relative px-3 text-white link xl:text-lg hover:text-primary focus:text-primary xl:px-5 2xl:px-8">
                      Lead Generation
                    </a>
                  </li>
                  <li className="hidden lg:inline-block">
                    <a href="#our-work" className="relative px-3 text-white link xl:text-lg hover:text-primary focus:text-primary xl:px-5 2xl:px-8">
                      Our Work
                    </a>
                  </li>
                  <li className="hidden lg:inline-block">
                    <a href="/contact" className="relative px-3 text-white link xl:text-lg hover:text-primary focus:text-primary xl:px-5 2xl:px-8">
                      Contact
                    </a>
                  </li>
                  <li className="hidden mx-3 ml-5 mr-0 xl:mx-5 2xl:mx-8 xl:mr-0 2xl:mr-0 md:inline-block">
                    <a href="tel:01159598900" className="align-middle btn btn--small btn--secondary-light lg:text-lg contactlink">
                      <FontAwesomeIcon className="inline-block w-4 h-4 mr-1" icon="phone-alt" size="sm" /> 0115 959 8900
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {/* NEW HERO */}

      <section className="mb-6 overflow-hidden my-24 md:my-32 lg:my-32 xl:my-24">
        <div className="container">
          <div className="flex flex-wrap items-center">
            <div className="w-full pr-6 mb-12 lg:w-5/12 content lg:mb-0 lg:pl-12 xl:pl-24 xl:pr-12">
              <div>
                <h1>Digital Marketing Specialists in the {page.title} industry</h1>
                <div className="content content--reset" dangerouslySetInnerHTML={{ __html: page.heroBlurb }}></div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 lg:ml-auto">
           
              { page.sectorGallery ? (
                <div className="flex flex-wrap -mx-2 md:-mx-4 xl:-mx-6 insta">
                  {page.sectorGallery.map(({ fluid }, index) => {
                    return(
                      <div className="w-1/2 px-2 mb-3 md:w-1/3 md:mb-5 xl:mb-8 md:px-4 xl:px-6 insta__object">
                        <Img fluid={ fluid } className="object-cover w-full max-w-full insta__img"/>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <></>
              )}
              
            </div>
          </div>
        </div>
      </section>

      { page.award.length ? (

      <div className="container flex flex-wrap items-center justify-center py-4 mb-6 md:md:items-stretch lg:mb-24">
        <div className="flex-wrap justify-center md:w-full md:justify-between md:-mx-2">
          <p class="h4 text-secondary-dark mb-5 block text-center">{page.awardsText}</p>
          <div class="flex justify-center">
            {page.award.map(({award}, i) => {
              return (
                <div className="flex flex-wrap items-center justify-start w-full p-2 m-2 border-2 outline-none max-w-170 border-grey">
                  <div className="w-full m-auto md:px-2">
                    <div className="m-auto text-center gatsby-image-wrapper">
                      <Img fluid={award.fluid} className="w-full" alt="award logo" />
                    </div>
                  </div>
              </div>
              )
            })}
          </div>
        </div>
      </div>

      ) : (
       
        <section className="pb-12 mb-12 border-b border-grey md:pb-16 xl:pb-20 md:mb-16 xl:mb-20">
          <div className="container">
            <ReviewsExtended />
          </div>
        </section>
      )}


      
      { page.workExamples.length > 0 && (
        <section className="mb-20 md:mb-24 xl:mb-32" id="our-work">
          {page.workExamples.map(({ heading, image }, index) => {
            return (
              <div className="pt-12 bg-white" key={`work-${index}`}>
                <div className={(index%2 === 0) ? `bg-secondary-dark` : `bg-secondary -mt-12`}>
                  <div className="container">
                    <div className="flex flex-wrap items-center md:-mx-4 xl:-mx-8">
                      <div className={(index%2 === 0) ? `w-full md:w-6/12 lg:w-5/12 md:px-4 xl:px-8 mb-5 md:mb-0` : `w-full md:w-6/12 lg:w-5/12 md:px-4 xl:px-8 mb-5 md:mb-0 md:order-2 ml-auto`} key={`img-${index}`}>
                        <div className="-mt-8 lg:-mt-12 xl:-mt-16">
                          <Img fluid={image.fluid} alt={heading} className="block w-full" />
                        </div>
                      </div>
                      <div className={(index%2 === 0) ? `w-full md:w-6/12 md:px-4 xl:px-8 mb-5 md:mb-0 pt-8 pb-20 md:py-12` : `w-full md:w-6/12 md:px-4 xl:px-8 mb-5 md:mb-0 pt-8 pb-6 md:py-12 md:order-1`} key={`text-${index}`}>
                        <h2 className="text-secondary-light" dangerouslySetInnerHTML={{ __html: heading }}></h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </section>
      )}

      { page.award.length ? (
      <section className="pb-12 mb-12 border-b border-grey md:pb-16 xl:pb-20 md:mb-16 xl:mb-20">
        <div className="container">
          <ReviewsExtended />
        </div>
      </section>
      ) : (
        <></>
      )}
      

      <section className="relative pt-10 overflow-hidden border-b md:pt-16 2xl:pt-24 border-grey" id="what-we-do">
        <div className="container">
          <div className="w-full md:w-2/3 lg:w-8/12">
            <h2 className="h1">What We Do</h2>
            <p className="text-lg lg:text-xl">With over 22 years of experience, we’re one of Nottingham’s longest standing and most reputable digital marketing companies. As a full-service agency, we have a wealth of knowledge and technical expertise and a proven track record of delivering results. Our ultimate aim is to ensure your business generates quality leads through a strategic and high-impact digital solution.</p>
          </div>

          <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8">
            <div className="flex flex-wrap pt-16 pb-10 pr-12 md:-mx-8 md:pb-0 md:pt-20 lg:pt-24">
              {page.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
                // Generate the top level slug from the models apiKey
                let slugged = slugify(model.apiKey + 's',{
                  lower: true,
                }).replace(/[_]/g, '-');
                let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

                return (
                  <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                    { icon && (
                      // @TODO convert this to SVG component?
                      <div className="w-full">
                        <svg width="35" height="35" className="block mb-8 lg:mb-10">
                          <image href={icon.url} src={icon.url} width="35" height="35"/>
                        </svg>
                      </div>
                    )}

                    <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                    { heroExcerpt && (
                      <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                    )}

                    <Link
                      // if it has a parent, pipe it into the link, if not leave it out 
                      to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                      className="block w-full mt-auto text-lg link text-secondary font-display"
                    >Read more <span className={`ml-2 inline-block text-primary`}><Triangle size={`small`} /></span></Link>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-wrap w-full pb-10 justi sm:-mx-2 md:pb-24 lg:pb-24 lg:mt-8">
              <div className="w-full mb-4 sm:w-auto sm:mb-0 sm:px-2">
                <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
              </div>
              <div className="w-full sm:w-auto sm:px-2">
                <Link to="/creative-services/" className="block w-full btn btn--primary">Creative Services</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="pb-12 mb-8 overflow-hidden border-b md:mb-16 lg:mb-24 md:pb-20 lg:pb-24 border-grey" id="industry-report">
        { page.featuredCaseStudy && (
          <FeaturedCaseStudy
            local={true}
            clientName={page.featuredCaseStudy.title}
            slug={page.featuredCaseStudy.slug}
            blurb={page.featuredCaseStudy.teaserBlurb}
            image={page.featuredCaseStudy.teaserIpadImage}
          />
        )}
      </section>

      <div className="container pb-12 mb-8 border-b md:mb-16 lg:mb-24 md:pb-20 lg:pb-24 border-grey" id="lead-generation">
        <div className="flex flex-wrap xl:items-center">
          <div className="w-full lg:w-1/2 xl:w-5/12">
            <div className="content content--reset" dangerouslySetInnerHTML={{ __html: page.content }}></div>

            <div className="overflow-hidden">
              <div className="flex flex-wrap sm:-mx-2">
                <div className="w-full mb-4 sm:w-auto sm:px-2 sm:mb-0">
                  <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
                </div>
                <div className="w-full sm:w-auto sm:px-2">
                  <Link to="/contact/" className="block w-full btn btn--outline">Contact us</Link>
                </div>
              </div>
            </div>
          </div>
          { page.contentImage && (
            <div className="hidden ml-auto lg:block lg:w-5/12 xl:w-1/2">
              <Img fluid={page.contentImage.fluid} className="w-full" />
            </div>
          )}
        </div>
      </div>

      <FormAudit />

      {/* We've worked with some great companies */}
      { page.companyLogos.length > 0 && (
        <section className="pb-12 mb-12 border-b border-grey md:pb-16 xl:pb-20 md:mb-16 xl:mb-20">
          <div className="container">
            <div className="flex flex-wrap items-center">
              <div className="w-full mb-12 lg:w-1/4 md:mb-5 lg:mb-0">
                <p className="text-lg text-center md:text-left md:text-xl">We've worked with some great { page.title } companies</p>
              </div>
              <div className="w-full lg:flex-1 lg:pl-8">
                <div className="flex flex-wrap items-center justify-center w-full md:-mx-4 xl:-mx-8">
                  {page.companyLogos.map(({ fluid, title }, index) => {
                    return (
                      <div className="w-7/12 mb-5 md:flex-1 md:px-4 xl:px-8 md:mb-0" key={index}>
                        <Img fluid={fluid} alt={title} className="block w-full" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      
      {/* <section id="contact">
        <FormCta />
      </section> */}
    </>
  )
}

export default Sector

export const query = graphql`
  query sectorPageQuery($slug: String!) {
    page: datoCmsSector(slug: { eq: $slug }) {
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      title
      heroBlurb
      content
      contentImage {
        fluid(
          maxWidth: 700
          imgixParams: {h: "775", w: "700"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      industryStats {
        heading
        text
      }
      awardsText
      award {
        award {
          fluid(
            maxWidth: 700
            imgixParams: {h: "400", w: "600"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      industryStatsBlurb
      sectorReportPdfDownload {
        url
      }
      workExamples {
        heading
        image {
          fluid(
            maxWidth: 1240
            imgixParams: {h: "1200", w: "1240", fit: "crop", crop: "faces"}) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      heroImage {
        fluid(
          maxWidth: 1200
          imgixParams: {h: "1200", w: "1200", fit: "crop", crop: "faces"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      companyLogos {
        fluid(
          maxWidth: 720
          imgixParams: {w: "720"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      featuredCaseStudy {
        slug
        title
        teaserBlurb
        teaserIpadImage {
          fluid(
            maxWidth: 900
            imgixParams: {h: "500", w: "900", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      ourServices {
        ... on DatoCmsService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
      sectorGallery {
        fluid(
          maxWidth: 900
          imgixParams: {h: "900", w: "900", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`